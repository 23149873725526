import classNames from './PopUpEndOfSkillSummary.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import {
  GameTypes,
  PopUpEndOfSkillSummaryProps,
  PopUpSummaryProps,
} from '@/types/game';
import { useEffect, useRef, useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { gameSelector } from '@/store/reducers/game';
import { SkillTypes } from '@/types/skill';
import UseLocalLang from '@/hooks/UseLocalLang';
import commonUtils from '@/utils/common';
import GameUtils from '@/utils/gameUtils';
import complete from '@/assets/svg/quiz-complete.svg';
import failed from '@/assets/svg/quiz-failed.svg';
import emptyBadge from '@/assets/images/empty-badge.png';
import TheButton from '@/components/TheButton/TheButton';
import { authSelector } from '@/store/reducers/auth';
import UserUtils from '@/utils/user';

const PopUpEndOfSkillSummary = ({
  className,
  selectedSkill,
  selectedTopic,
  selectedQuiz,
  tip,
  correctAnswers,
  totalQuestions,
  emitOnClosePopUp,
  emitOnExit,
  emitOnQuit,
  assessmentResult,
}: PopUpEndOfSkillSummaryProps) => {
  const { t } = useTranslation();
  const { selectedType } = useAppSelector(gameSelector);
  const { user } = useAppSelector(authSelector);
  const { isDesktop, isMobile } = UseScreenSize();
  const summaryRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const isAllowIndividualAdvancement =
    assessmentResult?.allowIndividualAdvancement;
  const score = +commonUtils.calcPercentage(
    correctAnswers ?? 0,
    totalQuestions ?? 0
  );

  const starsAmount = GameUtils.getStarsAmount(score);
  const isFailed = starsAmount < 2;

  const isStudent = UserUtils.isStudent(user);

  const getMainTitle = () => {
    if (isFailed) {
      return 'practice_makes_perfect';
    }

    return 'you_did_it';
  };

  const getSubTitle = () => {
    if (isFailed) {
      return `${t('skillsTestTryAgain')}`;
    }

    const scoreText = `<span style="font-weight: bold">${score}%</span>`;

    return `${t('you_passed_assessment')}\n ${t(
      'with_a_score_of'
    )} ${scoreText}`;
  };

  const getBottomTitle = () => {
    if (isAllowIndividualAdvancement) {
      return t('you_can_now_practice_level', {
        level: assessmentResult.levelInSkill,
      });
    }

    return t('in_order_to_proceed_ask_teacher');
  };

  const getBottomSubTitle = () => {
    if (isAllowIndividualAdvancement) {
      return t('goodLuck');
    }

    return t('remind_teacher_to_move_up');
  };
  return (
    <div
      className={classes(
        {
          container: isMobile,
        },
        className,
        classNames.popUpEndOfSkillSummary
      )}
    >
      {isMobile && (
        <div className={classNames.exitBtn}>
          <button onClick={emitOnExit}>{t('exit')}</button>
        </div>
      )}
      <div className={classNames.quizEndOfSkillSummary}>
        <div className={classNames.images}>
          <img
            className={classNames.stars}
            src={commonUtils.getStarImage(starsAmount)?.img ?? ''}
            alt="stars"
          />
          <div
            className={classNames.result}
            style={{ backgroundImage: `url(${isFailed ? failed : complete})` }}
          >
            <div className={classNames.text}>
              <span>{t('englishSkillsAssessment')}</span>
              <h3>{isFailed ? `${t('oops')}` : t('complete')}</h3>
            </div>
          </div>
        </div>
        <div className={classNames.content}>
          <div ref={summaryRef} className={classes(classNames.summary)}>
            <h3 className={classNames.title}>{t(getMainTitle())}</h3>
            <div
              className={classNames.description}
              dangerouslySetInnerHTML={{ __html: getSubTitle() }}
            ></div>

            {!isFailed && (
              <>
                <div className={classNames.badgeLevel}>
                  <img src={emptyBadge} alt="empty-badge" />
                  <span>{assessmentResult?.levelInSkill}</span>
                </div>

                {isStudent && (
                  <>
                    <h3 className={classNames.bottomTitle}>
                      {t(getBottomTitle())}
                    </h3>
                    <p className={classNames.bottomSubTitle}>
                      {t(getBottomSubTitle())}
                    </p>
                  </>
                )}
              </>
            )}

            {isMobile && (
              <TheButton
                className={classNames.button}
                text={!isFailed ? t('backHome') : t('tryAgain')}
                showArrow={!isFailed}
                emitOnClick={() =>
                  isFailed ? emitOnClosePopUp?.(isFailed) : emitOnExit?.()
                }
              />
            )}
          </div>

          {isDesktop && (
            <div className={classNames.buttonsWrapper}>
              <div className={classNames.buttons}>
                <TheButton
                  className={classNames.buttonTwo}
                  text={
                    isFailed
                      ? t('tryAgain')
                      : isAllowIndividualAdvancement
                      ? t('startTheNextLevel')
                      : t('backHome')
                  }
                  shouldRecolor={!isFailed && !isAllowIndividualAdvancement}
                  showArrow={
                    isFailed || (isAllowIndividualAdvancement && !isFailed)
                  }
                  emitOnClick={() =>
                    isFailed ? emitOnClosePopUp?.(isFailed) : emitOnExit?.()
                  }
                />
                {(isAllowIndividualAdvancement || isFailed) && (
                  <TheButton
                    className="flex-1"
                    plain
                    text={t('backHome')}
                    emitOnClick={emitOnExit}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopUpEndOfSkillSummary;
